<template>
  <div class="form-card p-4">
    <b-row>
      <b-col md="9">
        <h2>
          <Ellipsis>
            {{ item.name }}
          </Ellipsis>
        </h2>
      </b-col>

      <b-col v-if="canDelete">
        <Trash class="trash" @click="confirmDelete" />
      </b-col>
    </b-row>

    <b-row class="description">
      <b-col class="col-description h-100" cols="12">
        <p v-if="item.description ?? item.template">
          <strong>Descrição: </strong>
          <span v-html="item.description ?? item.template" />
        </p>
      </b-col>
    </b-row>
    
    <hr />

    <b-row class="mt-2 bottom align-items-center">
      <b-col cols="7">
        <span class="autoFillBtnLabel">
          {{ item.active ? 'Ativo' : 'Inativo' }}
        </span>
        <toggle-button
          class="autoFillBtn mb-1"
          :width="35"
          v-model="item.active"
          :sync="true"
          :height="22"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          @input="update(item)"
        />
      </b-col>
      <b-col cols="5" v-if="canEdit">
        <b-button
          class="ml-auto"
          variant="link"
          size="lg"
          @click="edit({ item })"
        >
          <Ellipsis>{{ textLink }}</Ellipsis>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'FormCard',
  components: {
    Trash: () => import('@/assets/icons/trash.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis.vue')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    textLink: {
      type: String,
      required: true
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    update: {
      type: Function,
      default: () => {}
    },
    edit: {
      type: Function,
      default: () => {}
    },
    delete: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    confirmDelete() {
      this.$swal({
        icon: 'error',
        html: `<div class="body-alert">Deseja realmente apagar <span>${this.item?.name}</span>? Não será possível desfazer essa alteração.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          await this.delete(this.item?.id)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-card {
  width: 100%;
  margin-left: 1%;
  text-align: left;
  height: 100%;
  background: var(--neutral-000);
  border: 1px solid var(--neutral-200);
  border-radius: 8px;

  .trash {
    cursor: pointer;
    float: right;
    width: 24px;
    stroke: var(--states-red-soft);
  }

  h2 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .description {
    text-overflow: ellipsis;

    .col-description {
      height: 5vh;
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
  }
  .bottom {
    height: 20px;
    .link {
      font-weight: 700;
      font-size: min(1.2vw, 16px);
    }
  }
}
</style>
